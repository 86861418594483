import { Flex, chakra, Image, Link } from '@chakra-ui/react';
import React from 'react';
import type { BannerProps } from './types';

const AdBannerInfo = ({className, platform, data}: BannerProps) => {
  const LinkInfo = (({src}:{src:string})=>{
    return (
      <Link
        href={data?.url}
        target="_blank"
        display="inline-flex"
        alignItems="center"
        columnGap={1}
      >
        <Image src={src} alt={data?.title}/>
      </Link>
    )
  })

  const banner = (() => {
    switch (platform) {
      case 'desktop': {
        return (
          <Flex className={className} w="728px" h="90px">
            <LinkInfo src={data?.src728x90}/>
          </Flex>
        );
      }
      case 'mobile': {
        return (
          <Flex className={className} w="270px" h="90px">
            <LinkInfo src={data?.src270x90}/>
          </Flex>
        );
      }
      default: {
        return (
          <>
            <Flex className={className} w="728px" h="90px" display={{base: 'none', lg: 'flex'}}>
              <LinkInfo src={data?.src728x90}/>
            </Flex>
            <Flex className={className} w="270px" h="90px" display={{base: 'flex', lg: 'none'}}>
              <LinkInfo src={data?.src270x90}/>
            </Flex>
          </>
        );
      }
    }
  })();

  return (
    <>
      {banner}
    </>
  );
};

export default chakra(AdBannerInfo);
