import { Flex, Link, Skeleton, chakra } from '@chakra-ui/react';
import React, { useMemo } from 'react';

import config from 'configs/app';
import useApiQuery from 'lib/api/useApiQuery';
import dayjs from 'lib/date/dayjs';
import useIsMobile from 'lib/hooks/useIsMobile';
import { EXPLORE_WEB, HOMEPAGE_STATS } from 'stubs/stats';
import GasInfoTooltip from 'ui/shared/gas/GasInfoTooltip';
import GasPrice from 'ui/shared/gas/GasPrice';
import TextSeparator from 'ui/shared/TextSeparator';

const TopBarStats = () => {
  const isMobile = useIsMobile();

  const {data, isPlaceholderData, isError, refetch, dataUpdatedAt} = useApiQuery('stats', {
    queryOptions: {
      placeholderData: HOMEPAGE_STATS,
      refetchOnMount: false,
    },
  });

  const {
    data: dataPriceInfo,
    isError: isErrorPrice,
    refetch: refetchPrice,
  } = useApiQuery('explore_token_price', {
    queryOptions: {
      refetchOnMount: false,
      placeholderData: EXPLORE_WEB,
      select: (dataPrice)=>{
        const {code, data} = dataPrice;
        if (code == 200) {
          return data;
        } else {
          return {};
        }
      }
    }
  });

  React.useEffect(() => {
    if (isPlaceholderData || !data?.gas_price_updated_at ) {
      return;
    }
    const endDate = dayjs(dataUpdatedAt).add(data.gas_prices_update_in, 'ms');
    const timeout = endDate.diff(dayjs(), 'ms');
    if (timeout <= 0) {
      return;
    }

    const timeoutId = window.setTimeout(() => {
      refetch().then(r => {
      });
      refetchPrice().then(r => {
      });
    }, timeout);

    return () => {
      window.clearTimeout(timeoutId);
    };
  }, [ isPlaceholderData, data?.gas_price_updated_at, dataUpdatedAt, data?.gas_prices_update_in, refetch, refetchPrice ]);

  if (isError && isErrorPrice) {
    return <div/>;
  }

  return (
    <Flex
      alignItems="center"
      fontSize="xs"
      fontWeight={500}
    >
      {dataPriceInfo?.price && (
        <Flex columnGap={1}>
          <Skeleton isLoaded={!isPlaceholderData}>
            <chakra.span color="text_secondary">{config.chain.currency.symbol} </chakra.span>
            <span>${Number(dataPriceInfo?.price).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 6
            })}</span>
          </Skeleton>
          {dataPriceInfo?.rate && (
            <Skeleton isLoaded={!isPlaceholderData}>
              <chakra.span color={Number(dataPriceInfo?.rate) >= 0 ? 'green.500' : 'red.500'}>
                {Number(dataPriceInfo?.rate).toFixed(2)}%
              </chakra.span>
            </Skeleton>
          )}
        </Flex>
      )}
      {!isMobile && data?.secondary_coin_price && config.chain.secondaryCoin.symbol && (
        <Flex columnGap={1} ml={data?.coin_price ? 3 : 0}>
          <Skeleton isLoaded={!isPlaceholderData}>
            <chakra.span color="text_secondary">{config.chain.secondaryCoin.symbol} </chakra.span>
            <span>${Number(data.secondary_coin_price).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 6
            })}</span>
          </Skeleton>
        </Flex>
      )}
      {dataPriceInfo?.price && config.features.gasTracker.isEnabled && <TextSeparator color="divider"/>}
      {data?.gas_prices && data.gas_prices.average !== null && config.features.gasTracker.isEnabled && (
        <Skeleton isLoaded={!isPlaceholderData}>
          <chakra.span color="text_secondary">Gas</chakra.span>
          <GasInfoTooltip data={data} dataUpdatedAt={dataUpdatedAt}
                          placement={!data?.coin_price ? 'bottom-start' : undefined}>
            <Link>
              <GasPrice data={data.gas_prices.average}/>
            </Link>
          </GasInfoTooltip>
        </Skeleton>
      )}
    </Flex>
  );
};

export default React.memo(TopBarStats);
