import { chakra, Skeleton } from '@chakra-ui/react';
import React from 'react';
import type { BannerPlatform } from './types';
import type { AdBannerProviders } from 'types/client/adProviders';
import config from 'configs/app';
import AdBannerInfo from './AdBannerInfo';
import useFetch from "lib/hooks/useFetch";
import { useQuery } from "@tanstack/react-query";
import type { ResourceError } from "lib/api/resources";
const url = '/assets/configs/adBanner.json';

const feature = config.features.adsBanner;

interface Props {
  className?: string;
  isLoading?: boolean;
  platform?: BannerPlatform;
  provider?: AdBannerProviders;
}

const AdBannerContent = ({ className, isLoading, provider, platform }: Props) => {
  const fetch = useFetch();
  const { data } = useQuery<unknown, ResourceError<unknown>, Record<string, any>>({
    queryKey: [ 'adBanner' ],
    queryFn: async() => fetch(url || '', undefined, { resource: 'adBanner' }),
    staleTime: Infinity,
  });
  const content = (() => {
    switch (provider) {
      case 'adbutler':
        return <AdBannerInfo platform={ platform } data={data?.adbutler}/>;
      case 'coinzilla':
        return <AdBannerInfo platform={ platform } data={data?.coinzilla}/>;
      case 'hype':
        return <AdBannerInfo platform={ platform } data={data?.hype}/>;
      case 'slise':
        return <AdBannerInfo platform={ platform } data={data?.slise}/>;
      default:
        return <AdBannerInfo platform={ platform } data={data?.slise}/>;
    }
  })();

  return (
    <Skeleton
      className={ className }
      isLoaded={ !isLoading }
      borderRadius="none"
      maxW={ ('adButler' in feature && feature.adButler) ? feature.adButler.config.desktop.width : '728px' }
      w="100%"
    >
      { content }
    </Skeleton>
  );
};

export default chakra(AdBannerContent);
